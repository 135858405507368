import { useCallback, useEffect, useRef } from 'react';
import { useLipscoreLanguage } from './useLipscoreLanguage';

const useLipscoreMount = () => {
  const ref = useRef();
  const retryRef = useRef(0);

  const mountWidgets = useCallback(() => {
    if (ref.current || retryRef.current) return;

    ref.current = setInterval(() => {
      retryRef.current += 1;

      if (retryRef.current > 10) {
        console.error('Failed to mount Lipscore widgets');
        return clearInterval(ref.current);
      }

      const success = window?.lipscore?.initWidgets?.();

      if (success) {
        console.log('Lipscore widgets mounted');
        return clearInterval(ref.current);
      } else {
        console.error('Failed attempt to mount Lipscore widgets');
      }
    }, 300);
  }, [ref]);

  return mountWidgets;
};

export const useLipscore = (lang = 'se') => {
  const mount = useLipscoreMount();
  const language = useLipscoreLanguage(lang);

  const getMountedScripts = useCallback(() => {
    try {
      const script = document.getElementById('lipscore-init');
      const css = document.querySelector(
        'link[href="https://static.lipscore.com/assets/lipscore-v1.css"]'
      );

      return {
        script,
        css
      };
    } catch (e) {
      console.error('Failed to get Lipscore scripts\n', e);

      return {
        script: null,
        css: null
      };
    }
  }, []);

  const initialize = useCallback(() => {
    try {
      const { script, css } = getMountedScripts();

      if (script && css) {
        return;
      }

      const tag = document.createElement('script');
      tag.id = 'lipscore-init';
      tag.src = `//static.lipscore.com/assets/${language}/lipscore-v1.js`;
      tag.async = true;
      tag.onload = mount;

      const apiKey = process.env.REACT_APP_LIPSCORE_API_KEY;

      window.lipscoreInit = function() {
        window.lipscore.init({
          apiKey: apiKey
        });
      };

      const head = document.getElementsByTagName('head')?.[0];

      if (!script) {
        head.appendChild(tag);
      }
    } catch (e) {
      console.error('Failed to initialize Lipscore\n', e);
    }
  }, [getMountedScripts, mount, language]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  useEffect(() => {
    mount();
  }, [mount]);
};
