import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useContext } from 'react';

export const useLipscoreLanguage = lang => {
  const { selectedChannel } = useContext(ChannelContext);

  try {
    // Only for MMOSD
    // Available languages are en, no, se, dk, fi, fr, de, br, cz, it, es, pl, nl, ja
    // Read more at: https://members.lipscore.com/widgets
    const language = selectedChannel?.url?.split('/')[3];
    return language;
  } catch (e) {
    console.error(e);
    return lang;
  }
};
