import React from 'react';
import { useLipscore } from '../hooks/useLipscore';

export const LipscoreSmallServiceReviewsBadgeShort = () => {
  useLipscore();

  return (
    <div
      class="lipscore-service-review-badge-small-short lipscore-no-border"
      data-ls-widget-height="45px"
      data-ls-widget-width="300px"
    ></div>
  );
};
