import React, { useContext, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theming/Theming';
import SiteContentContext from '../../SiteContent/SiteContentContext';
import { ContentRender } from '../../ContentRender/ContentRender';
import PopupRoute from '../../ui/PopupRoute/PopupRoute';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CustomNewsletter from '../../Newsletter/CustomNewsletter';
import { LipscoreSmallServiceReviewsBadgeShort } from '../../Lipscore/ServiceReview/LipscoreSmallServiceReviewsBadgeShort';
import { LipscoreServiceReviewList } from '../../Lipscore/ServiceReview/LipscoreServiceReviewList';

const FooterWrapper = styled('footer')`
  z-index: 2;
  a {
    text-decoration: none;
    color: ${theme.colors.primary};
  }
  > div {
    padding: 5rem 1rem;
    text-align: center;
    h1,
    h2,
    h3,
    h4,
    h5,
    p {
      color: ${theme.footer.color};
    }
    h1,
    h2,
    h3,
    h4,
    h5 {
      font-weight: ${theme.weights.bold};
    }
  }
  .footer-content {
    color: ${theme.footer.color};
    background: ${theme.footer.background};
    &.footer1 {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      > h1,
      > h2,
      > h3,
      > h4,
      > h5 {
        width: 100%;
      }
    }
    &.footer2 {
      > ul {
        padding: 1rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        > li {
          padding: 1rem;
        }
      }
    }
    > ul {
      padding: 2rem;
      > li {
        font-size: 1.2rem;
        ${theme.above.lg} {
          font-size: 1.4rem;
        }
      }
    }
  }
  .newsletter-box {
    .newsletter-wrapper {
      max-width: 400px;
      margin: 0 auto;
      input#newsletter {
      }
      button[type='submit'] {
      }
    }
    .newsletter-text {
      p {
        font-size: 1.2rem;
        margin: 1rem 0;
        ${theme.above.lg} {
          font-size: 1.4rem;
        }
        svg {
          margin-left: 5px;
        }
      }
    }
  }
`;

const LipscoreWrapper = styled('div')`
  padding: 2rem 1rem 4rem;
  text-align: center;
  > div {
    max-width: 500px;
    margin: 0 auto;
  }
`;

const Footer = () => {
  const {
    newsletter,
    newsletterBelow,
    newsletterBelowText,
    newsletterBelowLink,
    showNewsletter,
    newsletterInput,
    newsletterSubmit,
    newsletterThanks,
    newsletterAlready,
    newsletterError,
    newsletterErrorInvalid,
    footer1,
    footer2,
    rest
  } = useContext(SiteContentContext);

  const [popup, setPopup] = useState(false);

  return (
    <>
      <ContentRender data={{ items: rest }} />
      <LipscoreWrapper>
        <LipscoreSmallServiceReviewsBadgeShort />
        <LipscoreServiceReviewList />
      </LipscoreWrapper>
      <FooterWrapper>
        <div className="footer-content footer1">{footer1}</div>
        {showNewsletter && (
          <div className="newsletter-box">
            <div className="newsletter-text">{newsletter}</div>
            <CustomNewsletter
              {...{
                newsletterInput,
                newsletterSubmit,
                newsletterThanks,
                newsletterAlready,
                newsletterError,
                newsletterErrorInvalid
              }}
            />
            <div className="newsletter-text below">{newsletterBelow}</div>
            {newsletterBelowLink && newsletterBelowText && (
              <div className="newsletter-text below">
                <p>
                  <a
                    href={newsletterBelowLink}
                    onClick={e => {
                      e.preventDefault();
                      setPopup(!popup);
                    }}
                  >
                    {newsletterBelowText}
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </a>
                </p>
                {popup && (
                  <PopupRoute
                    path={newsletterBelowLink}
                    close={() => setPopup(false)}
                  />
                )}
              </div>
            )}
          </div>
        )}
        <div className="footer-content footer2">{footer2}</div>
      </FooterWrapper>
    </>
  );
};

export default Footer;
